import { Box, Grid, Pagination, Paper, Stack, StyledEngineProvider } from "@mui/material";
import React from "react";
import Header from "../UI/header";
import { DatgridStyles, paperStyle } from "../assets/styles";
import { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { DataGrid, GridFooterContainer, GridPagination, GridToolbar, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid";
import useFetch from "../hooks/usefetch";

/////////// Pagination ////////////
export function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <StyledEngineProvider injectFirst>
        <Stack spacing={2} sx={{ marginLeft: "15px", color: "white" }}>
          <Pagination
            sx={{
              "& .MuiPaginationItem-root": {
                color: "#fff",
              },
            }}
            showFirstButton
            showLastButton
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
          />
        </Stack>
      </StyledEngineProvider>
    );
  }
  
  /////////// Footer//////////////////
  export function CustomFooter() {
    return (
      <GridFooterContainer>
        <CustomPagination />
        <GridPagination />
      </GridFooterContainer>
    );
  }

const ReplacementList = () => {

    const columns = [
        { field: "unitreplacement_id", headerName: "ID", flex: 0.3 },

        {
            field: "manufacturername",
            headerName: "Manufacturer",
            flex: 0.6,
          },
        {
          field: "model",
          headerName: "Model",
          flex: 0.5,
        },
       
        { field: "defect_name", headerName: "Defect Type", flex: 1 },
        { field: "defectiveunit", headerName: "Defective Asset", flex: 0.8 },
        { field: "replacedunit", headerName: "Replaced Asset", flex: 0.8 },
        {
          field: "reserve_wh_id",
          headerName: "Location",
          flex: 0.5,
        },
      ];


      const replacementList = useFetch(`${process.env.REACT_APP_API}/unit/replaced_asset_list`).data

    useEffect(() => {
        AOS.init();
    },[])

  return (
    <Box>
      <Header>Replacement List</Header>
      <Grid container direction={"row"} columnSpacing={2}>
        <Grid sm={12} item data-aos="fade-up">
          <Paper {...paperStyle}>
          <DataGrid
                    {...DatgridStyles}
                    rows={replacementList && replacementList.length > 0 ? replacementList : ""}
                    columns={columns}
                    localeText  =
                    {{
                        footerRowSelected: CustomPagination
                    }}
                    slots = {{ toolbar: GridToolbar, footer:CustomFooter }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    
                    }}
                    initialState={{
                        ...replacementList.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]} 
                    getRowId={(row) => row.unitreplacement_id} 
                />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReplacementList;
