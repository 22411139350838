import React from "react";
import styles from "./thinking.module.css"

const Thinking = () => {
  return (
    <div className={styles['lds-ellipsis']}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Thinking;
