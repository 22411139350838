import {React, useEffect, useState} from 'react'
import { Box, Grid, Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Typography, Divider, Tab, TextField, OutlinedInput, FormControl, InputLabel } from '@mui/material'
import { paperMui, GraphBox, TableProps, TabProps, TabPanelProps, selectProps, formProps } from "../assets/styles"
import CanvasJSReact from '@canvasjs/react-charts';
import Header from "../UI/header"
import { Stack } from '@mui/system';
import "rsuite/dist/rsuite.min.css";
import useFetch from '../hooks/usefetch';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import TabContext from '@mui/lab/TabContext';
import {DateRangePicker, TabList, TabPanel } from '@mui/lab';
import { Progress } from 'rsuite';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Label } from '@mui/icons-material';



 
const Dashboard = () => {

    const [date, setDate] = useState(new Date())

    function getDate(e){
        setDate(e) ;
        refetch()    
    }


    // const lastWeekDate = new Date(endDate.getFullYear(),endDate.getMonth(),endDate.getDate()-7)


    const CanvasJSChart = CanvasJSReact.CanvasJSChart;
    const options = {
        theme: "light2",
        height: "400",
        toolTip: {
            backgroundColor: "rgba(0, 0, 0)",
            borderColor: "rgba(0, 0, 0)",
            cornerRadius: 4,
            fontStyle: "italic",
            fontColor: "white",
        },
        backgroundColor: "rgba(39, 55, 77, 0)",
        animationEnabled: true,
        animationDuration: 1500,
        axisX: {
            valueFormatString: "MMM",
            labelFontFamily: "Montserrat",
            labelFontColor: "#ccc",
            labelFontSize   : 13,
            gridThickness: 0.2,
            lineDashType: "shortDash",
            
        },
        axisY: {
            labelFontFamily: "Montserrat",
            labelFontColor: "#ccc",
            labelFontSize   : 13,
            gridThickness: 0.2,
            tickLength : 0,
        },
        data: [{
            yValueFormatString: "$#,###",
            xValueFormatString: "MMMM",
            type: "splineArea",
            fillOpacity: .45, 
            color : "rgba(56, 148, 102, 0.9)",
            lineColor: "rgba(255, 255, 255, 1)",
            dataPoints: [
                
                { x: new Date(2017, 0), y: 26960 },
                { x: new Date(2017, 1), y: 27980 },
                { x: new Date(2017, 2), y: 42800 },
                { x: new Date(2017, 3), y: 32400 },
                { x: new Date(2017, 4), y: 35260 },
                { x: new Date(2017, 5), y: 33900 },
                { x: new Date(2017, 6), y: 40000 },
                { x: new Date(2017, 7), y: 52500 },
                { x: new Date(2017, 8), y: 32300 },
                { x: new Date(2017, 9), y: 42000 },
                { x: new Date(2017, 10), y: 37160 },
                { x: new Date(2017, 11), y: 38400 }
            ]
        }]
    }

    const optionsBar = {
        theme: "light2",
        height: "400",
        toolTip: {
            backgroundColor: "rgba(0, 0, 0)",
            borderColor: "rgba(0, 0, 0)",
            cornerRadius: 4,
            fontStyle: "italic",
            fontColor: "white",
        },
        backgroundColor: "rgba(39, 55, 77, 0)",
        animationEnabled: true,
        animationDuration: 1500,
        axisX: {
            valueFormatString: "MMM",
            labelFontFamily: "Montserrat",
            labelFontColor: "#ccc",
            labelFontSize   : 13,          
        },
        axisY: {
            labelFontFamily: "Montserrat",
            labelFontColor: "#ccc",
            labelFontSize   : 13,
            gridThickness: 0.2,
            lineDashType: "longDash",
            tickLength : 0,
        },
        dataPointMaxWidth : 20,
        data: 
        [
            {
                yValueFormatString: "#,###",
                xValueFormatString: "MMMM",
                type: "column", 
                // color : "rgba(56, 148, 102, 0.9)",
                dataPoints: [
                    
                    { x: new Date(2017, 0), y: 26960 },
                    { x: new Date(2017, 1), y: 27980 },
                    { x: new Date(2017, 2), y: 42800 },
                    { x: new Date(2017, 3), y: 32400 },
                    { x: new Date(2017, 4), y: 35260 },
                    { x: new Date(2017, 5), y: 33900 },
                    { x: new Date(2017, 6), y: 40000 },
                    { x: new Date(2017, 7), y: 52500 },
                    { x: new Date(2017, 8), y: 32300 },
                    { x: new Date(2017, 9), y: 42000 },
                    { x: new Date(2017, 10), y: 37160 },
                    { x: new Date(2017, 11), y: 38400 }
                ]
            }
        ]
    }

    const optionsDounut = {
        animationEnabled: true,
        theme: "light2",
        height : 350,
        toolTip: {
            backgroundColor: "rgba(0, 0, 0)",
            borderColor: "rgba(0, 0, 0)",
            cornerRadius: 4,
            fontStyle: "italic",
            fontColor: "white",
            fontFamily : "Montserrat"
        },
        backgroundColor: "rgba(39, 55, 77, 0)",
        subtitles: [{
            text: "71% Positive",
            verticalAlign: "center",
            fontSize: 20,
            fontFamily : "Montserrat",
            fontColor : "rgba(56, 148, 102, 0.9)",
            fontWeight : "700",
            dockInsidePlotArea: true
        }],
        data: [{
            type: "pie",
            radius:  "180%", 
            innerRadius: "90%",
            type: "doughnut",
            // showInLegend: true,
            indexLabel: "{name}: {y}",
            yValueFormatString: "#,###'%'",
            indexLabelFontFamily: "Montserrat",
            indexLabelFontColor : "#fff",
            indexLabelFontSize : 14,
            dataPoints: [
                { name: "Unsatisfied", y: 5, indexLabelLineThickness: 2, },
                { name: "Very Unsatisfied", y: 31, indexLabelLineThickness: 2, },
                { name: "Very Satisfied", y: 40, indexLabelLineThickness: 2, },
                { name: "Satisfied", y: 17, indexLabelLineThickness: 2, },
                { name: "Neutral", y: 7, indexLabelLineThickness: 2, }
            ]
        }]
    }

    const [refresh, setRefresh] = useState(false)

    const datePayload = {
        "start_date": dayjs(date)
      }

      const shouldDisableDate = (date) => {
        return new Date(date).getDay() !== 0 // Disable all days except Sunday
      };

    const unitdata = useFetch(`${process.env.REACT_APP_API}/unit/view_unit_dashboard_status`).data
    const weeklyInstallation = useFetch(`${process.env.REACT_APP_API}/unit/weekly_installation`, refresh, datePayload).data
    const unitTableData = unitdata['data']

    const dayWiseOrders = useFetch(`${process.env.REACT_APP_API}/warehouse/day_wise_warehouse_in_and_out_status`).data

    const refetch = function(){
        setRefresh(e => !e) 
    }


    const orderDashboardArrivingDeparture = useFetch(`${process.env.REACT_APP_API}/warehouse/order_dashboard_arriving_departure`).data

    useEffect(() => {
        AOS.init();
      }, [])

    const [value, setValue] = useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

      const position = [51.505, -0.09]
      const position1 = [51.505, -0.010]
      const position2 = [51.506, -0.15]
      const position3 = [51.508, -0.25]
      const position4 = [51.503, 0.01]

      const progressData = orderDashboardArrivingDeparture.filter(val => val.orderstataus === 'InProgress')
      const cancelledData = orderDashboardArrivingDeparture.filter(val => val.orderstataus === 'Cancelled')
      const completedData = orderDashboardArrivingDeparture.filter(val => val.orderstataus === 'Completed')

      function TabTablecontainer({tableData = []}){

        return(
            <Box pr={1} sx = {{width : '100%',height : "350px",  overflowY : "auto"}}>
            <TableContainer>
            <Table stickyHeader >
                <TableHead {...TableProps[0]}>
                    <TableRow>
                        <TableCell className='text-center text-upper font-green font-14 font-500'>Manufacturer</TableCell>
                        <TableCell className='text-center text-upper font-green font-14 font-500'>Order</TableCell>
                        <TableCell className='text-center text-upper font-green font-14 font-500'>Model</TableCell>
                        <TableCell className='text-center text-upper font-green font-14 font-500'>Item</TableCell>
                        <TableCell className='text-center text-upper font-green font-14 font-500'>From</TableCell>
                        <TableCell className='text-center text-upper font-green font-14 font-500'>To</TableCell>
                        <TableCell className='text-center text-upper font-green font-14 font-500'>Order Status</TableCell>
                        {/* <TableCell className='text-center text-upper font-green font-14 font-500'>Status</TableCell> */}
                        <TableCell className='text-center text-upper font-green font-14 font-500'>Quantity</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody {...TableProps[1]}>
                    { tableData?.length> 0 && tableData.map((e) => {
                      return(
                        <TableRow>
                            <TableCell className='text-center font-white font-12 font-500'>{e.manufacturername}</TableCell>
                            <TableCell className='text-center font-white font-12 font-500'>{e.order_name}</TableCell>
                            <TableCell className='text-center font-white font-12 font-500'>{e.model}</TableCell>
                            <TableCell className='text-center font-white font-12 font-500'>{e.itemname}</TableCell>
                            <TableCell className='text-center font-white font-12 font-500'>{e.sourcestatename}</TableCell>
                            <TableCell className='text-center font-white font-12 font-500'>{e.destination_statename}</TableCell>
                            <TableCell className='text-center font-white font-12 font-500'>{e.order_status_name}</TableCell>
                            {/* <TableCell className='text-center font-white font-12 font-500'>{e.orderstataus}</TableCell> */}
                            <TableCell className='text-center font-white font-12 font-500'>{e.bu_qty}</TableCell>
                        </TableRow>
                        )
                    })
                    }
                </TableBody>
            </Table>
            </TableContainer>
        </Box>
        )
      }

      ////////////// Search Filter /////////////////////


      const [searchTerm, setSearchTerm] = useState('');

      const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
      };
    
      const filteredTableData = unitTableData?.filter((row) =>
        row.statename.toLowerCase().includes(searchTerm.toLowerCase())
      );
      
    return (
        <Box mb = {2} >
            <Header>Dashboard</Header>
            <Box mt = {2} mb = {1}>
                <Grid container direction={'row'} alignItems={'center'} columnSpacing={2} rowSpacing={1} data-aos="fade-right">
                    <Grid lg={4} md={12} sm={12} mb={1} item>
                        <Paper {...paperMui[1]}>
                            <Stack direction="row" width="100%" spacing={1} alignItems={'center'} >
                                <Grid lg={8} md={8} sm={8}>
                                    <Typography variant='h1' className='font-400 font-18 font-white'>Weekly Orders</Typography> 
                                    <Typography variant='h3' className='font-montserrat font-600 font-white'>$17K</Typography> 
                                </Grid>
                                <Grid lg={4} md={4} sm={4}>
                                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                                        <Progress.Line vertical percent={30} status="active" showInfo= {false} strokeColor="#26577C" />
                                        <Progress.Line vertical percent={40} status="active" showInfo= {false} strokeColor="#F39F5A" />
                                        <Progress.Line vertical percent={50} status="active" showInfo= {false} strokeColor="#AE445A" />
                                        <Progress.Line vertical percent={60} status="active" showInfo= {false} strokeColor="#79AC78" />
                                    </Box>
                                </Grid>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid lg={4} md={12} sm={12} mb={1} item data-aos="fade-up">
                        <Paper {...paperMui[1]}>
                            <Stack direction="row" width="100%" spacing={1} alignItems={'center'}>
                                <Grid lg={8} md={8} sm={8}>
                                    <Typography variant='h1' className='font-400 font-18 font-white'>Total Orders</Typography> 
                                    <Typography variant='h3' className='font-montserrat font-600 font-white'>$58K</Typography> 
                                </Grid>
                                <Grid lg={4} md={4} sm={4}>
                                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                                        <Progress.Line vertical percent={45} status="active" showInfo= {false} />
                                        <Progress.Line vertical percent={90} status="active" showInfo= {false} />
                                        <Progress.Line vertical percent={30} status="active" showInfo= {false} />
                                        <Progress.Line vertical percent={100} status="active" showInfo= {false} />
                                    </Box>
                                </Grid>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid lg={4} md={12} mb={1} sm={12} item data-aos="fade-right">
                        <Paper {...paperMui[1]}>
                            <Stack direction="row" width="100%" spacing={1} alignItems={'center'} >
                                <Grid lg={8} md={8} sm={8}>
                                    <Typography variant='h1' mb={1} className='font-400 font-18 font-white'> Available </Typography> 
                                    <Divider light variant="left" color="white"/>
                                    <Stack direction="row" width="100%" spacing={2}>
                                        <Grid lg={8}>
                                        <Typography variant='h3' mt={1} className='font-400 font-14 font-white'>AC Charging Chargers</Typography>
                                        </Grid>
                                        <Grid lg={4}>
                                        <Typography variant='h3' mt={1} className='font-600 font-14 font-white'>60%</Typography>
                                        </Grid>
                                    </Stack>
                                    <Stack direction="row" width="100%" spacing={2}>
                                        <Grid lg={8}>
                                        <Typography variant='h3' mt={1} className='font-400 font-14 font-white'>DC Charging Chargers</Typography>
                                        </Grid>
                                        <Grid lg={4}>
                                        <Typography variant='h3' mt={1} className='font-600 font-14 font-white'>40%</Typography>
                                        </Grid>
                                    </Stack>
                                </Grid>
                                <Grid lg={4} md={4} sm={4}>
                                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                                        <Progress.Line vertical percent={60} status="active" showInfo= {false} />
                                        <Progress.Line vertical percent={30} status="active" showInfo= {false} />
                                        <Progress.Line vertical percent={50} status="active" showInfo= {false} />
                                        <Progress.Line vertical percent={60} status="active" showInfo= {false} />
                                    </Box>
                                </Grid>
                            </Stack>
                        </Paper>
                    </Grid>

                    <Grid sm={12} item mb={1} data-aos="fade-up">
                        <Paper {...paperMui[1]} elevation={8} >
                            <Box pr={1} sx = {{maxHeight : "400px", minHeight : "300px", overflowY : "auto"}}>
                                <TableContainer>
                                <Table stickyHeader >
                                    <TableHead {...TableProps[0]}>
                                        <TableRow>
                                            <TableCell className='text-center text-upper font-green font-14 font-500'>Order ID</TableCell>
                                            <TableCell className='text-center text-upper font-green font-14 font-500'>Order</TableCell>
                                            <TableCell className='text-center text-upper font-green font-14 font-500'>From Sender</TableCell>
                                            <TableCell className='text-center text-upper font-green font-14 font-500'>To Receiver</TableCell>
                                            <TableCell className='text-center text-upper font-green font-14 font-500'>Manufacturer</TableCell>
                                            <TableCell className='text-center text-upper font-green font-14 font-500'>Item</TableCell>
                                            <TableCell className='text-center text-upper font-green font-14 font-500'>Modal</TableCell>
                                            <TableCell className='text-center text-upper font-green font-14 font-500'>Status</TableCell>
                                            <TableCell className='text-center text-upper font-green font-14 font-500'>Quantity</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody {...TableProps[1]}>
                                        { dayWiseOrders?.length> 0 && dayWiseOrders.map((e) => {
                                        return(
                                            <TableRow>
                                                <TableCell className='text-center font-white font-12 font-500'>{e.order_id}</TableCell>
                                                <TableCell className='text-center font-white font-12 font-500'>{e.order_name}</TableCell>
                                                <TableCell className='text-center font-white font-12 font-500'>{e.destinationwarehouseid}</TableCell>
                                                <TableCell className='text-center font-white font-12 font-500'>{e.sourcewarehouseid}</TableCell>
                                                <TableCell className='text-center font-white font-12 font-500'>{e.manufacturername}</TableCell>
                                                <TableCell className='text-center font-white font-12 font-500'>{e.itemname}</TableCell>
                                                <TableCell className='text-center font-white font-12 font-500'>{e.model}</TableCell>
                                                <TableCell className='text-center font-white font-12 font-500'>{e.order_status_name}</TableCell>
                                                <TableCell className='text-center font-white font-12 font-500'>{e.qty}</TableCell>
                                            </TableRow>
                                            )
                                        })
                                        }
                                    </TableBody>
                                </Table>
                                </TableContainer>
                            </Box>
                        </Paper>
                    </Grid>


                    <Grid lg={8} md={12} sm={12} item mb={1} data-aos="fade-up">
                        <Paper {...paperMui[1]} elevation={8} style={{height:'470px'}}>
                            <TabContext value={value}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example" {...TabProps} >
                                        <Tab style={{backgroundColor: "#00d2ff20", margin: "0 3px", borderRadius: '4px 4px 0 0'}} className = "font-16 font-500 text-capital font-white" label="Order In Progress" value="1"  wrapped/>
                                        <Tab style={{backgroundColor: "#00d2ff20", margin: "0 3px", borderRadius: '4px 4px 0 0'}} className = "font-16 font-500 text-capital font-white" label="Order Completed" value="2"  wrapped />
                                        <Tab style={{backgroundColor: "#00d2ff20", margin: "0 3px", borderRadius: '4px 4px 0 0'}} className = "font-16 font-500 text-capital font-white" label="Order Cancelled" value="3"  wrapped />
                                    </TabList>
                                <TabPanel value="1" {...TabPanelProps}>
                                    <TabTablecontainer tableData={progressData}/>
                                </TabPanel>
                                <TabPanel value="2" {...TabPanelProps}>
                                    <TabTablecontainer tableData={completedData}/>
                                </TabPanel>
                                <TabPanel value="3" {...TabPanelProps}>
                                    <TabTablecontainer tableData={cancelledData}/>
                                </TabPanel>
                            </TabContext>
                        </Paper>
                    </Grid>

                    <Grid lg={4} md={12} sm={12} mb={1} item data-aos="fade-up">
                        <Paper {...paperMui[1]} elevation={8} style={{height:'470px'}}>
                            <Box>
                                <Typography variant='h1' className='font-500 font-18 font-white'>Yearly Installation</Typography>
                            </Box>
                            <Box >
                                <CanvasJSChart options = {options} />
                            </Box>
                        </Paper>
                    </Grid>
                     
                    <Grid lg={4} md={12} sm={12} mb={1} item data-aos="fade-right">
                        <Paper {...paperMui[1]} elevation={8}>
                            <Box {...GraphBox[1] }>
                                <Box>
                                    <Typography variant='h1' className='font-500 font-18 font-white'>Market Review</Typography>
                                </Box>
                                <Box height={400}>
                                    <CanvasJSChart options = {optionsDounut} />
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>

                    
                    <Grid lg={8} md={12} sm={12} mb={1} item data-aos="fade-right">
                        <Paper {...paperMui[1]} elevation={8}>
                            <Box {...GraphBox[1] }>
                                <Grid alignItems={'center'} justifyContent={"space-between"} container item flex={"row"}>
                                    <Grid sm={4}>
                                    <Typography variant='h1' className='font-500 font-18 font-white'>Weekly Installation</Typography>
                                    </Grid>
                                   
                                    <Grid sm={4}>
                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker 
                                                label="Date"
                                                shouldDisableDate={shouldDisableDate}
                                                onChange={(e)=> getDate(e)} 
                                                value={dayjs(date)}
                                                maxDate={dayjs(new Date())}
                                                sx={{
                                                    svg: { color: "white" },
                                                    input: {  color: "white" , borderColor: "white" },
                                                    label: {  color: "white" }
                                                  }}

                                                  style={{
                                                    label: { color: "white" ,borderColor: "white"}
                                                  }}
                                                  />
                                        </DemoContainer>
                                        </LocalizationProvider> */}


                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                            label="Date"
                                            shouldDisableDate={shouldDisableDate}
                                            maxDate={dayjs(new Date())}
                                            onChange={(e) => getDate(e)}
                                            value={dayjs(date)}
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                color: 'white',
                                                },
                                                '& .MuiInputLabel-root': {
                                                color: 'white',
                                                '&.MuiInputLabel-shrink': {
                                                    color: 'white', // Set the label color when it shrinks (i.e., when not empty)
                                                },
                                                '&.Mui-focused': {
                                                    color: 'white', // Set the focused label color to white
                                                },
                                                },
                                                '& .MuiInput-underline:before, & .MuiInput-underline:after': {
                                                borderBottom: '1px solid white', // Set the border color here
                                                },
                                                '& .MuiInput-root': {
                                                '&:hover:not(.Mui-disabled):before': {
                                                    borderBottom: '1px solid white', // Set the border color on hover
                                                },
                                                },
                                                '& .MuiPickersDay-day:not(.MuiPickersDay-dayDisabled):hover, & .MuiPickersDay-daySelected': {
                                                //   backgroundColor: 'rgba(56, 148, 102, 0.8)', // Your desired color
                                                color: 'white',
                                                },
                                                '& .MuiPickersDay-dayDisabled': {
                                                color: 'grey', // Your desired color for disabled days
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'white !important', // Set the border color for the calendar icon
                                                },
                                                '& .MuiIconButton-root': {
                                                color: 'white', // Set the color of the calendar icon
                                                },
                                            }}
                                            />
                                        </DemoContainer>
                                        </LocalizationProvider>

                                    </Grid>                                                                  
                                </Grid>
                                <Box pr={1} sx = {{width : '100%',height : "92%", overflowY : "auto", margin : "10px 0px"}}>
                                    <TableContainer >
                                        <Table stickyHeader >
                                            <TableHead {...TableProps[0]}>
                                                <TableRow>
                                                    <TableCell className='text-center text-upper font-green font-14 font-500'>State</TableCell>
                                                    <TableCell className='text-center text-upper font-green font-14 font-500'>City</TableCell>
                                                    <TableCell className='text-center text-upper font-green font-14 font-500'>Location</TableCell>
                                                    <TableCell className='text-center text-upper font-green font-14 font-500'>Manufacturer</TableCell>
                                                    <TableCell className='text-center text-upper font-green font-14 font-500'>Model</TableCell>
                                                    <TableCell className='text-center text-upper font-green font-14 font-500'>Charger</TableCell>
                                                    <TableCell className='text-center text-upper font-green font-14 font-500'>Ready for installation</TableCell>
                                                    <TableCell className='text-center text-upper font-green font-14 font-500'>Installed</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody {...TableProps[1]}>
                                                { weeklyInstallation?.length> 0 && weeklyInstallation.map((e) => {
                                                  return(
                                                    <TableRow>
                                                        <TableCell className='text-center font-white font-12 font-500'>{e.statename}</TableCell>
                                                        <TableCell className='text-center font-white font-12 font-500'>{e.cityname}</TableCell>
                                                        <TableCell className='text-center font-white font-12 font-500'>{e.location}</TableCell>
                                                        <TableCell className='text-center font-white font-12 font-500'>{e.manufacturername}</TableCell>
                                                        <TableCell className='text-center font-white font-12 font-500'>{e.model}</TableCell>
                                                        <TableCell className='text-center font-white font-12 font-500'>{e.chargertype}</TableCell>
                                                        <TableCell className='text-center font-white font-12 font-500'>{e.readyforinstallation}</TableCell>
                                                        <TableCell className='text-center font-white font-12 font-500'>{e.installed}</TableCell>
                                                    </TableRow>
                                                    )
                                                })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
             
                    <Grid lg={6} md={12} sm={12} mb={1} item data-aos="fade-up">
                        <Paper {...paperMui[1]} elevation={8}>
                            <Box {...GraphBox[1] }>
                                <Box>
                                    <Typography variant='h1' className='font-500 font-18 font-white'>Charger Installed By States</Typography>
                                </Box>

                                <Box mt={1} >
                                <OutlinedInput
                                
                                {...selectProps}
                                    placeholder="Search by State"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                                </Box>
                                <Box pr={1} sx = {{width : '100%',height : "80%", overflowY : "auto", margin : "10px 0px"}}>
                                     
                                    <TableContainer >
                                        <Table stickyHeader >
                                            <TableHead {...TableProps[0]}>
                                                <TableRow>
                                                    <TableCell className='text-center text-upper font-green font-14 font-500'>Item</TableCell>
                                                    <TableCell className='text-center text-upper font-green font-14 font-500'>State</TableCell>
                                                    <TableCell className='text-center text-upper font-green font-14 font-500'>City</TableCell>
                                                    <TableCell className='text-center text-upper font-green font-14 font-500'>Available</TableCell>
                                                    <TableCell className='text-center text-upper font-green font-14 font-500'>FLC OK</TableCell>
                                                    <TableCell className='text-center text-upper font-green font-14 font-500'>Not OK</TableCell>
                                                    {/* <TableCell className='text-center text-upper font-green font-14 font-500'>T&A</TableCell> */}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody {...TableProps[1]}>
                                                { filteredTableData?.map((e) => {
                                                  return(
                                                    <TableRow>
                                                        <TableCell className='text-center font-white font-12 font-500'>{e.itemname}</TableCell>
                                                        <TableCell className='text-center font-white font-12 font-500'>{e.statename}</TableCell>
                                                        <TableCell className='text-center font-white font-12 font-500'>{e.citiname}</TableCell>
                                                        <TableCell className='text-center font-white font-12 font-500'>{e.availableusecount}</TableCell>
                                                        <TableCell className='text-center font-white font-12 font-500'>{e.flcokcount}</TableCell>
                                                        <TableCell className='text-center font-white font-12 font-500'>{e.flcnotokcount}</TableCell>
                                                        {/* <TableCell className='text-center font-white font-12 font-500'>{e.undertrainingandawareness}</TableCell> */}
                                                    </TableRow>
                                                    )
                                                })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid lg={6} md={12} sm={12} mb={1} item data-aos="fade-up">
                        <Paper {...paperMui[1]} elevation={8}>
                            <Box {...GraphBox[1] }>
                                <Box>
                                    <Typography variant='h1' className='font-500 font-18 font-white'>Charger Installed On Map</Typography>
                                </Box>
                                <Box sx={{ margin : "10px 0px" }}>
                                    <MapContainer center={position} zoom={11} scrollWheelZoom={false}>
                                        <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        <Marker position={position}>
                                            <Popup>
                                                Brindisa Mart
                                            </Popup>
                                        </Marker>
                                        <Marker position={position1}>
                                            <Popup>
                                                King's College London
                                            </Popup>
                                        </Marker>
                                        <Marker position={position2}>
                                            <Popup>
                                                King's College London
                                            </Popup>
                                        </Marker>
                                        <Marker position={position3}>
                                            <Popup>
                                                King's College London
                                            </Popup>
                                        </Marker>
                                        <Marker position={position4}>
                                            <Popup>
                                                King's College London
                                            </Popup>
                                        </Marker>
                                    </MapContainer>
                                    {/* <CanvasJSChart options = {optionsDounut} /> */}
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid lg={12} md={12} sm={12} mb={1} item data-aos="fade-up">
                        <Paper {...paperMui[1]} elevation={8}>
                            <Box>
                                <Typography variant='h1' className='font-500 font-18 font-white'>Yearly Orders</Typography>
                            </Box>
                            <Box >
                                <CanvasJSChart options = {optionsBar} />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Dashboard