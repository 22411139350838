import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { paperMui } from "../assets/styles";

const Header = (props) => {
    return (
        <Box mb={2}>
            <Paper {...paperMui[0]}>
                <Typography variant='h1' className={`headerText font-white`} > {props.children}</Typography>
            </Paper>
        </Box>
    )
}

export default Header