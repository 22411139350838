import React from 'react'
import { Box, Container, Grid, Typography, Button} from '@mui/material'
import { buttonProps } from '../assets/styles';
import { loginRequest } from '../Auth';
import Cookies from 'js-cookie'

const Logout = (props) => {
    const logouts = () => {
        Cookies.remove('access_token')
        Cookies.remove('refresh_token')
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('userMenu')
        props.instance.logoutRedirect(props.deactivate);
        window.location.href = '/'
    }
    React.useEffect(() => {
        logouts();
    },[props.instance])
    return (
        <Box className = "dark-theme-image">
            <Container maxWidth = "xl">
                <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
                    <Grid  item xs = {12} sm = {12} md = {4} lg = {4} xl = {4} marginY={15}>
                        <Typography variant="h3" className='font-montserrat font-800 font-green' marginY={2} >
                            Electric Vehicle Inventory Management
                        </Typography>
                        <Typography variant="h6" className='font-500 font-white' marginY={2} >
                            Streamline Your EV Inventory with our Inventory Management Application.
                        </Typography>
                        <Box display={'flex'} alignItems={''} justifyContent={'start'}>
                            <Button {...buttonProps[7] } className='font-montserrat' onClick={() => props.instance.loginRedirect({ ...loginRequest})} >Log In </Button>
                        </Box>
                    </Grid>
                </Grid>
                
            </Container>
        </Box>
    )
}

export default Logout