import React from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import { useState, useEffect } from "react";

const VoiceAssist = (props) => {
  const setIamMessage = props.setIamVoice;
  const setListening = props.setListening
  const submitButton = props.submitButton

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const [voiceListening, setVoiceListening] = useState("");

  
  function sentMessage() {
    if (!listening && voiceListening.length > 0) {
      // alert(`message sent successfully ${transcript}`);
      submitButton()
      setVoiceListening("");
      
      setTimeout(()=>{
        resetTranscript()
      },1000)
      clearTimeout()
    }
  }

  useEffect(() => {
    setVoiceListening(transcript);
  }, [transcript]);

  useEffect(()=>{
    sentMessage()
  },[listening])
  
  setIamMessage(voiceListening);
  setListening(listening)

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }


  return (
    <KeyboardVoiceIcon
      onClick={SpeechRecognition.startListening}
      className={listening ? "icon-green" : "icon-white"}
    />
    // <div>
    //   {/* <p>Microphone: {listening ? "on" : "off"}</p>
    //   <button onClick={SpeechRecognition.startListening}>Start</button>
    //   <button onClick={SpeechRecognition.stopListening}>Stop</button>
    //   <button onClick={resetTranscript}>Reset</button> */}

    //   {/* <p className="font-white">{transcript}</p>
    //   {sentMessage()} */}
    // </div>
  );
};
export default VoiceAssist;
