import { useEffect, useRef } from 'react';
import Cookies from 'js-cookie'

const Login = (props) => {
    const apicallsrestrict = useRef(false);
    Cookies.set('access_token', props.token, { expires: 1, secure: true });
    Cookies.set('refresh_token', props.token, { expires: 1, secure: true });
    
    const getTasks = async() => {
        try 
        {
            document.getElementById('load').style.display = 'flex';
            const response = await fetch(
              `${process.env.REACT_APP_API}/user/permissionsByRole`,
                {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                    credentials: "include",
                    mode: "cors",
                }
            );
            const data = await response.json();
            if(response.status === 200)
            {
                window.localStorage.setItem("token",JSON.stringify(props.token));
                if(data !== null)
                {
                    let detail = [{
                        "ID"            :   data.userid,
                        "RoleName"      :   data.role,
                        "RoleCode"      :   data.roleCode,
                        "MainIds"       :   data.main_menu,
                        "SubIds"        :   data.sub_menu,
                        "Permissions"   :   data.permissions,
                        "Menu"          :   0,
                        "SubMenu"       :   0,
                        "Name"          :   props.details.username
                    }]
        
                    window.localStorage.setItem("userMenu",JSON.stringify(detail));
                }
                document.getElementById('load').style.display = 'none';
        
               if(data.userid.slice(8,11) === 'WIC'){
                window.location.pathname = "/warehousedashboard"; 
                }
                else{
                window.location.pathname = "/home"; 
                }
            }
            else
            {
                document.getElementById('load').style.display = 'none';
            }
        }
        catch (error) 
        {
            document.getElementById('load').style.display = 'none';
        }

    }

    useEffect(() => {
        if (apicallsrestrict.current) return;
        apicallsrestrict.current = true;
        getTasks();
    },[])
}

export default Login