import React from 'react';
import Login from './login/Login'
import './App.css'
import Router from './Router'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalProvider } from '@azure/msal-react'
import { loginRequest } from "./Auth";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import { buttonProps } from './assets/styles';
import Logout from './login/Logout';


const WrappedView = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const deActiveAccount = {
        account: instance.getAccountByHomeId((activeAccount) ? activeAccount.homeAccountId : null),
    };
    let userdata = JSON.parse(localStorage.getItem("userMenu"));
    const pathname = window.location.pathname;
    
    return(
        <>
            <AuthenticatedTemplate>
                {
                    (activeAccount && activeAccount !== null && activeAccount.homeAccountId !== '') ?
                        (userdata && userdata.length > 0 ) ?
                            (pathname === '/' || pathname === '') ?
                                <Login details = {activeAccount} token = {activeAccount.idToken} /> 
                            :
                                (pathname === '/logout') ?
                                    <Logout instance = {instance} deactivate = {deActiveAccount}/>
                                :
                                    <Router />
                        :
                            <Login details = {activeAccount} token = {activeAccount.idToken} /> 
                    :
                        (pathname === '/login' || pathname === '/' || pathname === '') ?
                            <Box className = "dark-theme-image">
                                <Container maxWidth = "xl">
                                    <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
                                        <Grid  item xs = {12} sm = {12} md = {4} lg = {4} xl = {4} marginY={15}>
                                            <Typography variant="h3" className='font-montserrat font-800 font-green' marginY={2} >
                                                Electric Vehicle Inventory Management
                                            </Typography>
                                            <Typography variant="h6" className='font-500 font-white' marginY={2} >
                                                Streamline Your EV Inventory with our Inventory Management Application.
                                            </Typography>
                                            <Box display={'flex'} alignItems={''} justifyContent={'start'}>
                                                <Button {...buttonProps[7] } className='font-montserrat' onClick={() => instance.loginRedirect({ ...loginRequest})} >Log In </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    
                                </Container>
                            </Box>
                        :
                            <></>
                        
                }
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Box className = "dark-theme-image">
                    <Container maxWidth = "xl">
                        <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
                            <Grid  item xs = {12} sm = {12} md = {4} lg = {4} xl = {4} marginY={15}>
                                <Typography variant="h3" className='font-montserrat font-800 font-green' marginY={2} >
                                    Electric Vehicle Inventory Management
                                </Typography>
                                <Typography variant="h6" className='font-500 font-white' marginY={2} >
                                    Streamline Your EV Inventory with our Inventory Management Application.
                                </Typography>
                                <Box display={'flex'} alignItems={''} justifyContent={'start'} marginY={10}>
                                    <Button {...buttonProps[7] } className='font-montserrat' onClick={() => instance.loginRedirect({ ...loginRequest})} >Log In </Button>
                                </Box>
                            </Grid>
                        </Grid>
                        
                    </Container>
                </Box>
            </UnauthenticatedTemplate>
        </>
    )
}
const App = ({instance}) => {
    
    
    return (
        <div>
            <div id = 'load'></div>
            <MsalProvider instance={ instance }>
                <WrappedView/>
            </MsalProvider>
        </div>
    )
}

export default App