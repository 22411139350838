import {React, useEffect, useState} from 'react'
import { Box, Grid, Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Typography, Divider, Tab } from '@mui/material'
import { paperMui, GraphBox, TableProps, TabProps, TabPanelProps } from "../assets/styles"
import CanvasJSReact from '@canvasjs/react-charts';
import Header from "../UI/header"
import { Stack } from '@mui/system';
import "rsuite/dist/rsuite.min.css";
import useFetch from '../hooks/usefetch';
import AOS from 'aos';
import 'aos/dist/aos.css';
import TabContext from '@mui/lab/TabContext';
import { TabList, TabPanel } from '@mui/lab';

 
const WarehouseDashboard = () => {


    const datavalue1 = [
        { name: "Order Placed", y: 70, indexLabelLineThickness: 2, color: "#18ff8c" },
        { name: "Transit", y: 30, indexLabelLineThickness: 2, color: "rgba(31, 31, 35, 0.3)" },
    ]

    const datavalue2 = [
        { name: "Order Placed", y: 80, indexLabelLineThickness: 2, color: "#18ff8c" },
        { name: "Transit", y: 20, indexLabelLineThickness: 2, color: "rgba(31, 31, 35, 0.3)" },
    ]

    const datavalue3 = [
        { name: "AC Chargers", y: 60, indexLabelLineThickness: 2, color: "#18ff8c" },
        { name: "DC Chargers", y: 40, indexLabelLineThickness: 2, color: "#4477CE" },
    ]

 
    const CanvasJSChart = CanvasJSReact.CanvasJSChart;
 
    const optionsDounut1 = {
        animationEnabled: true,
        theme: "light2",
        height : 110,
        width: 130,
        backgroundColor: "rgba(39, 55, 77, 0)",
        toolTip: {
            backgroundColor: "rgba(0, 0, 0)",
            borderColor: "rgba(0, 0, 0)",
            cornerRadius: 4,
            fontStyle: "italic",
            fontColor: "white",
            fontFamily : "Montserrat"
        },
        
        subtitles: [{
            text: "71%",
            verticalAlign: "center",
            fontSize: 14,
            fontFamily : "Montserrat",
            fontColor : "#fff",
            fontWeight : "500",
            dockInsidePlotArea: true
        }],
        data: [{
            type: "pie",
            radius:  "200%", 
            innerRadius: "70%",
            type: "doughnut",
            indexLabelFontFamily: "Montserrat",
            indexLabelFontColor : "#fff",
            indexLabelFontSize : 10,
            dataPoints: datavalue1
        }]
    }

    const optionsDounut2 = {
        animationEnabled: true,
        theme: "light2",
        height : 110,
        width: 130,
        backgroundColor: "rgba(39, 55, 77, 0)",
        toolTip: {
            backgroundColor: "rgba(0, 0, 0)",
            borderColor: "rgba(0, 0, 0)",
            cornerRadius: 4,
            fontStyle: "italic",
            fontColor: "white",
            fontFamily : "Montserrat"
        },
        
        subtitles: [{
            text: "80%",
            verticalAlign: "center",
            fontSize: 14,
            fontFamily : "Montserrat",
            fontColor : "#fff",
            fontWeight : "500",
            dockInsidePlotArea: true
        }],
        data: [{
            type: "pie",
            radius:  "200%", 
            innerRadius: "70%",
            type: "doughnut",
            indexLabelFontFamily: "Montserrat",
            indexLabelFontColor : "#fff",
            indexLabelFontSize : 10,
            dataPoints: datavalue2
        }]
    }

    const optionsDounut3 = {
        animationEnabled: true,
        theme: "light2",
        height : 110,
        width: 130,
        backgroundColor: "rgba(39, 55, 77, 0)",
        toolTip: {
            backgroundColor: "rgba(0, 0, 0)",
            borderColor: "rgba(0, 0, 0)",
            cornerRadius: 4,
            fontStyle: "italic",
            fontColor: "white",
            fontFamily : "Montserrat"
        },
        
        subtitles: [{
            text: "100%",
            verticalAlign: "center",
            fontSize: 14,
            fontFamily : "Montserrat",
            fontColor : "#fff",
            fontWeight : "500",
            dockInsidePlotArea: true
        }],
        data: [{
            type: "pie",
            radius:  "200%", 
            innerRadius: "70%",
            type: "doughnut",
            indexLabelFontFamily: "Montserrat",
            indexLabelFontColor : "#fff",
            indexLabelFontSize : 10,
            dataPoints: datavalue3
        }]
    }

    const orderDashboardArrivingDeparture = useFetch(`${process.env.REACT_APP_API}/warehouse/order_dashboard_arriving_departure`).data

    const warehouseUnitCount = useFetch(`${process.env.REACT_APP_API}/warehouse/getDataByWarehouse`).data

    useEffect(() => {
        AOS.init();
      }, [])

    const [value, setValue] = useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

      const progressData = orderDashboardArrivingDeparture.filter(val => val.orderstataus === 'InProgress')
      const cancelledData = orderDashboardArrivingDeparture.filter(val => val.orderstataus === 'Cancelled')
      const completedData = orderDashboardArrivingDeparture.filter(val => val.orderstataus === 'Completed')

      function TabTablecontainer({tableData = []}){

        return(
            <Box pr={1} sx = {{width : '100%',maxHeight : "400px", minHeight : "250px", overflowY : "auto"}}>
            <TableContainer>
            <Table stickyHeader >
                <TableHead {...TableProps[0]}>
                    <TableRow>
                        <TableCell className='text-center text-upper font-green font-14 font-500'>Manufacturer</TableCell>
                        <TableCell className='text-center text-upper font-green font-14 font-500'>Order</TableCell>
                        <TableCell className='text-center text-upper font-green font-14 font-500'>Type</TableCell>
                        <TableCell className='text-center text-upper font-green font-14 font-500'>Item</TableCell>
                        <TableCell className='text-center text-upper font-green font-14 font-500'>From</TableCell>
                        <TableCell className='text-center text-upper font-green font-14 font-500'>To</TableCell>
                        <TableCell className='text-center text-upper font-green font-14 font-500'>Order Status</TableCell>
                        {/* <TableCell className='text-center text-upper font-green font-14 font-500'>Status</TableCell> */}
                        <TableCell className='text-center text-upper font-green font-14 font-500'>Quantity</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody {...TableProps[1]}>
                    { tableData?.length> 0 && tableData.map((e) => {
                      return(
                        <TableRow>
                            <TableCell className='text-center font-white font-12 font-500'>{e.manufacturername}</TableCell>
                            <TableCell className='text-center font-white font-12 font-500'>{e.order_name}</TableCell>
                            <TableCell className='text-center font-white font-12 font-500'>{e.model_powertype_mfg_year}</TableCell>
                            <TableCell className='text-center font-white font-12 font-500'>{e.itemname}</TableCell>
                            <TableCell className='text-center font-white font-12 font-500'>{e.sourcestatename}</TableCell>
                            <TableCell className='text-center font-white font-12 font-500'>{e.destination_statename}</TableCell>
                            <TableCell className='text-center font-white font-12 font-500'>{e.order_status_name}</TableCell>
                            {/* <TableCell className='text-center font-white font-12 font-500'>{e.orderstataus}</TableCell> */}
                            <TableCell className='text-center font-white font-12 font-500'>{e.bu_qty}</TableCell>
                        </TableRow>
                        )
                    })
                    }
                </TableBody>
            </Table>
            </TableContainer>
        </Box>
        )
      }




      

    return (
        <Box mb = {2} >
            <Header> Warehouse Dashboard</Header>
            <Box mt = {2} mb = {1}>
                <Grid container direction={'row'} alignItems={'center'} columnSpacing={2} rowSpacing={1} data-aos="fade-right">
                    <Grid lg={4} md={12} sm={12} mb={1} item>
                        <Paper {...paperMui[1]}>
                            <Stack direction="row" width="100%" spacing={1} alignItems={'center'} >
                                <Grid lg={8} md={8} sm={8}>
                                    <Typography variant='h1' className='font-400 font-18 font-white'>Weekly Orders</Typography> 
                                    <Typography variant='h3' className='font-montserrat font-600 font-white'>$2K</Typography> 
                                </Grid>
                                <Grid lg={4} md={4} sm={4}>
                                    <Box height={112}>
                                        <CanvasJSChart options = {optionsDounut1} />
                                    </Box>
                                </Grid>
                            </Stack>
                        </Paper>
                    </Grid>

                    <Grid lg={4} md={12} sm={12} mb={1} item data-aos="fade-up">
                        <Paper {...paperMui[1]}>
                            <Stack direction="row" width="100%" spacing={1} alignItems={'center'}>
                                <Grid lg={8} md={8} sm={8}>
                                    <Typography variant='h1' className='font-400 font-18 font-white'>Total Orders</Typography> 
                                    <Typography variant='h3' className='font-montserrat font-600 font-white'>$26K</Typography> 
                                </Grid>
                                <Grid lg={4} md={4} sm={4}>
                                    <Box height={112}>
                                        <CanvasJSChart options = {optionsDounut2}/>
                                    </Box>
                                </Grid>
                            </Stack>
                        </Paper>
                    </Grid>

                    <Grid lg={4} md={12} mb={1} sm={12} item data-aos="fade-right">
                        <Paper {...paperMui[1]}>
                            <Stack direction="row" width="100%" spacing={1} alignItems={'center'} >
                                <Grid lg={8} md={8} sm={8}>
                                    <Typography variant='h1' mb={1} className='font-400 font-18 font-white'> Available </Typography> 
                                    <Divider light variant="left" color="white"/>
                                    <Stack direction="row" width="100%" spacing={2}>
                                        <Grid lg={8}>
                                        <Typography variant='h3' mt={1} className='font-400 font-14 font-white'>AC Charging Chargers</Typography>
                                        </Grid>
                                        <Grid lg={4}>
                                        <Typography variant='h3' mt={1} className='font-600 font-14 font-white'>60%</Typography>
                                        </Grid>
                                    </Stack>
                                    <Stack direction="row" width="100%" spacing={2}>
                                        <Grid lg={8}>
                                        <Typography variant='h3' mt={1} className='font-400 font-14 font-white'>DC Charging Chargers</Typography>
                                        </Grid>
                                        <Grid lg={4}>
                                        <Typography variant='h3' mt={1} className='font-600 font-14 font-white'>40%</Typography>
                                        </Grid>
                                    </Stack>
                                </Grid>
                                <Grid lg={4} md={4} sm={4}>
                                    <Box height={112}>
                                        <CanvasJSChart options = {optionsDounut3}/>
                                    </Box>
                                </Grid>
                            </Stack>
                        </Paper>
                    </Grid>
                            

                    <Grid lg={12} item mb={1} data-aos="fade-up">
                        <Paper {...paperMui[1]} elevation={8}>
                            <Box pr={1} sx = {{width : '100%',maxHeight : "400px", minHeight : "250px", overflowY : "auto"}}>
                                <TableContainer>
                                    <Table stickyHeader >
                                        <TableHead {...TableProps[0]}>
                                            <TableRow >
                                                <TableCell className='text-center text-upper font-green font-14 font-500' >Warehouse ID</TableCell>
                                                <TableCell className='text-center text-upper font-green font-14 font-500'>Asset Model</TableCell>
                                                <TableCell className='text-center text-upper font-green font-14 font-500'>Status</TableCell>
                                                <TableCell className='text-center text-upper font-green font-14 font-500'>MFG Year</TableCell>
                                                {/* <TableCell className='text-center text-upper font-green font-14 font-500'>EBU</TableCell>
                                                <TableCell className='text-center text-upper font-green font-14 font-500'>ECU</TableCell>
                                                <TableCell className='text-center text-upper font-green font-14 font-500'>EVT</TableCell> */}
                                                <TableCell className='text-center text-upper font-green font-14 font-500'>Total Asset</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody {...TableProps[1]}>
                                            { warehouseUnitCount?.length> 0 && warehouseUnitCount.map((e) => { 
                                            return(
                                                <TableRow>
                                                    <TableCell className='text-center font-white font-12 font-500'>{e.warehouseid}</TableCell>
                                                    <TableCell className='text-center font-white font-12 font-500'>{e.unitmodel}</TableCell>
                                                    <TableCell className='text-center font-white font-12 font-500'>{e.status}</TableCell>
                                                    <TableCell className='text-center font-white font-12 font-500'>{e.mfyear}</TableCell>                            
                                                    {/* <TableCell className='text-center font-white font-12 font-500'>{e.bu_count}</TableCell>
                                                    <TableCell className='text-center font-white font-12 font-500'>{e.cu_count}</TableCell>
                                                    <TableCell className='text-center font-white font-12 font-500'>{e.vt_count}</TableCell> */}
                                                    <TableCell className='text-center text-upper font-green font-14 font-500'>{e.bu_count + e.cu_count + e.vt_count}</TableCell>
                                                </TableRow>
                                                )
                                            })
                                            } 
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>                            
                        </Paper>
                    </Grid>


                    <Grid lg={12} item mb={1} data-aos="fade-up">
                        <Paper {...paperMui[1]} elevation={8}>
                            <TabContext value={value}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example" {...TabProps} >
                                        <Tab style={{backgroundColor: "#00d2ff20", margin: "0 3px", borderRadius: '4px 4px 0 0'}} className = "font-16 font-500 text-capital font-white" label="Order In Progress" value="1"  wrapped/>
                                        <Tab style={{backgroundColor: "#00d2ff20", margin: "0 3px", borderRadius: '4px 4px 0 0'}} className = "font-16 font-500 text-capital font-white" label="Order Completed" value="2"  wrapped />
                                        <Tab style={{backgroundColor: "#00d2ff20", margin: "0 3px", borderRadius: '4px 4px 0 0'}} className = "font-16 font-500 text-capital font-white" label="Order Cancelled" value="3"  wrapped />
                                    </TabList>
                                <TabPanel value="1" {...TabPanelProps}>
                                    <TabTablecontainer tableData={progressData}/>
                                </TabPanel>
                                <TabPanel value="2" {...TabPanelProps}>
                                    <TabTablecontainer tableData={completedData}/>
                                </TabPanel>
                                <TabPanel value="3" {...TabPanelProps}>
                                    <TabTablecontainer tableData={cancelledData}/>
                                </TabPanel>
                            </TabContext>
                        </Paper>
                    </Grid>

                </Grid>
            </Box>
        </Box>
    )
}

export default WarehouseDashboard